import React from "react"
import { graphql, PageProps } from "gatsby"

import { GlobalFooter } from "components/global-footer"
import { Layout } from "components/layout"
import { SEO } from "components/seo/seo"

import { BlocksWrapper } from "components/blocks-wrapper/BlocksWrapper"
import { BlogPost, SocialShare } from "components/blog"
import { BlogSchema } from "components/schema/BlogSchema"
import { Breadcrumbs, BreadcrumbsGroup } from "components/breadcrumbs"
import { Button } from "components/button"

import {
  BlogPostData,
  SingleArticle,
  SingleNavigation,
} from "components/blog/_types"

type PageContext = PageProps["pageContext"] & {
  breadcrumb: BreadcrumbsGroup
  linkedPagesUrlPathCodex: Record<string, string>
  next?: SingleNavigation
  noIndex: boolean
  parentSlug: string
  prev?: SingleNavigation
  relatedArticles: SingleArticle[]
  slug: string
}

type PageDataElement = {
  elements: BlogPostData
}

type PageData = {
  allKontentItemBlogPost: {
    nodes: PageDataElement[]
  }
}

interface BlogPostProps extends PageProps {
  data: PageData
  pageContext: PageContext
}

const Blog: React.FC<BlogPostProps> = ({ location, data, pageContext }) => {
  const [blogPost] = data?.allKontentItemBlogPost?.nodes

  const {
    blog_post_content,
    featured_image,
    global_footer,
    heading,
    published_date,
    seo_metadata__canonical_link,
    seo_metadata__meta_description,
    seo_metadata__meta_title,
  } = blogPost.elements

  const {
    // next,
    // prev,
    breadcrumb,
    linkedPagesUrlPathCodex,
    parentSlug,
    relatedArticles,
  } = pageContext

  const { hash, pathname } = location
  const { slug } = pageContext

  return (
    <Layout
      hash={hash}
      linkedPagesUrlPathCodex={linkedPagesUrlPathCodex}
      location={slug}
      pathName={pathname}
    >
      <SEO
        canonical={seo_metadata__canonical_link?.value ?? ""}
        description={seo_metadata__meta_description?.value ?? ""}
        image={featured_image}
        title={seo_metadata__meta_title?.value ?? ""}
      />

      <BlogSchema
        description={seo_metadata__meta_description?.value ?? ""}
        heading={heading.value}
        image={featured_image.value[0]}
        publishedDate={published_date?.value ?? ""}
      />

      <Breadcrumbs
        crumbLabel={heading.value}
        crumbs={breadcrumb.crumbs}
        hiddenCrumbs={["/company", "/blog/posts", "/blog/post"]}
      />

      {blog_post_content && (
        <div className="u-content-block-padding">
          <BlogPost
            content={blog_post_content}
            featuredImage={featured_image.value[0]}
            heading={heading?.value}
            publishedDate={published_date?.value ?? ""}
          />

          <Button
            children="View All Articles"
            extraClassNames="fr-container fr-blog-post__btn u-buttons-group"
            to={parentSlug ? `/${parentSlug}` : "/blog"}
            variant="outline-accent"
          />

          <SocialShare />
        </div>
      )}
      {global_footer && global_footer?.value?.length > 0 && (
        <GlobalFooter
          content={global_footer}
          relatedArticles={relatedArticles}
        />
      )}
    </Layout>
  )
}

export const getPost = graphql`
  query getPost($slug: String!) {
    allKontentItemBlogPost(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          seo_metadata__meta_title {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__canonical_link {
            value
          }
          ...BlogPostFragment
          global_footer {
            value {
              ...GlobalFooterFragment
            }
          }
        }
      }
    }
  }
`
export default Blog
